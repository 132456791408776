const QUERY_KEYS = {
  ASSETS_CREATE: 'create assets',
  ASSETS_UPDATE: 'update assets',
  ASSETS_CATEGORIES: 'get assets categories',
  ASSETS_YEAR_PLANNER_ACTIVITIES: 'get assets activities',
  ASSETS_YEAR_PLANNER_ACTIVITY: 'get assets activity',
  ASSETS_INFORMATION_CHOICES: 'get assets information choices',
  ASSETS_GET_SINGLE: 'get single asset data',
  PRICING_INFO: 'pricing_info',
  GET_USER_DATA: 'get_user_data',
  GET_USER_KPI: 'get_user_kpi',
  GET_WORKPLACE_TEMPLATE: 'get_workplace_template',
  CREATE_WORKPLACE_TEMPLATE: 'create_workplace_template',
  DELETE_WORKPLACE_TEMPLATE: 'delete_workplace_template',
  GET_ALL_WORKPLACES: 'get_all_workplaces',
  CREATE_WORKPLACE: 'create workplace',
  GET_WORKPLACE: 'get workplace',
  GET_WORKPLACE_TEMPLATE_VALUES: 'get workplace template values',
  SET_DEFAULT_WORKPLACE: 'set default workplace',
  GET_SINGLE_SELF_TEST: 'get self test data',
  GET_SELF_TEST_PROGRESS_INSTANCE: 'get self test progress instance',
  CREATE_SELF_TEST_PROGRESS: 'create self test progress instance',
  ANSWER_QUESTION: 'answer question',
  GET_RECOMMENDED_CONTENT: 'get recommended content for dashboard',
  GET_SELF_TEST_RESULT: 'finish test and get self test result',
  GET_SELF_TEST_RESULT_PROGRESS_DATA: 'get progress data after finishing test',
  EDIT_TEST_ANSWER: 'edit self test question answer',
  GET_ORGANIZATION_INFO: 'get organization info',
  GET_ORGANIZATION_PAYMENT_STAGE_INFO: 'get organization payment stage info',
  REMAINING_RETENTION_DAYS: 'remaining retention days',
  REMAINING_TRIAL_DAYS: 'remaining trial days',
  GET_STRIPE_URL: 'get stripe url',
  GET_FEATURE_FLAGS: 'get feature flags',
  TABLE_FILTER: 'table filter',
  GET_CURRENT_USER: 'get current user',
  GET_WORKPLACE_DOCUMENT: 'get workplace document',
  ASSET_CONTACTS_GET_SINGLE: 'get single asset contact',
  ASSET_CONTACTS_GET_CATEGORIES: 'get asset contact categories',
  GET_ALL_ASSET_TASKS_BY_OWNER: 'get all asset tasks by owner',
  GET_USER_ASSET_TASK_BY_ID: 'get user asset task by id',
  GET_ORGANIZATION_ASSET_TASK_BY_ID: 'get organization asset task by id',
  GET_ORGANIZATION_ASSET_TASK_KPI: 'get organization KPI',
  GET_ORGANIZATION_ASSET_FILTERS_DATA: 'get organization filters data',
  GET_OTP_METHODS: 'get otp methods',
  GET_OTP_DEVICE_INFO: 'get otp device info',
  GET_OTP_DEVICES_LIST_FOR_AUTH: 'get otp devices list for auth',
  GET_RISK_ANALYSES_ASSETS_BY_OWNER: 'get risk analysis assets by owner',
  GET_ASSETS_AVAILABLE_FOR_RISK_ANALYSIS: 'get assets available for risk analysis',
  GET_SINGLE_RISK_ANALYSIS: 'get single risk analysis',
  GET_SINGLE_RISK: 'get single risk',
  GET_ALL_ASSET_RISKS: 'get all asset risks',
  GET_RISK_ANALYSIS_MATRIX: 'get risk analysis matrix',
  GET_ALL_INCIDENT_FOR_USER: 'get all incidents for user',
};

export default QUERY_KEYS;
