import React, { useMemo } from 'react';

import { useFormik } from 'formik';
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as yup from 'yup';

import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import FormCheckbox from 'components/FormCheckbox';
import FormTextInput from 'components/FormTextInput';
import assetRisksApi from 'config/api/assetRisks/assetRisks';
import { CreateAssetRiskForm } from 'config/api/assetRisks/assetRisks.types';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import RISK_ANALYSIS_CONSEQUENCES_SELECT from 'config/dictionaries/RISK_ANALYSIS_CONSEQUENCES_SELECT';
import RISK_ANALYSIS_DECISION_SELECT from 'config/dictionaries/RISK_ANALYSIS_DECISION_SELECT';
import RISK_ANALYSIS_PROBABILITY_SELECT from 'config/dictionaries/RISK_ANALYSIS_PROBABILITY_SELECT';
import EVENTS from 'config/events/pubsub';
import asset_risk_messages from 'messages/asset_risk_messages';
import general_messages from 'messages/general_messages';
import validation_messages from 'messages/validation_messages';
import { Id } from 'types/Id';

import RISK_ANALYSIS_PLACE_SELECT from '../../../config/dictionaries/RISK_ANALYSIS_PLACE_SELECT';
import FormSelect from '../../FormSelect';
import ButtonsGrid from '../_components/ButtonsGrid';
import DialogGrid from '../_components/DialogGrid';

type Props = {
  open: boolean;
  onClose: () => void;
  riskAnalysisId: string;
  riskId?: number;
};

type CreateAssetRiskFormInput = Omit<CreateAssetRiskForm, 'riskAnalysisId'>;

const AssetRiskDialog: React.FC<Props> = ({ open, onClose, riskAnalysisId, riskId }) => {
  const isEdit = useMemo(() => Boolean(riskId), [riskId]);
  const FORM_ID = `AddAssetRiskForm-${isEdit ? 'edit' : 'add'}`;
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  // assertion - isEdit checks it
  const mutation = useMutation(isEdit ? assetRisksApi.updateAssetRisk(riskId as Id) : assetRisksApi.createAssetRisk);

  const onSubmit = async (formData: CreateAssetRiskFormInput) => {
    await mutation.mutateAsync({ ...formData, riskAnalysisId });
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_RISK_ANALYSIS_MATRIX, riskAnalysisId] });
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_SINGLE_RISK_ANALYSIS, riskAnalysisId] });
    PubSub.publish(EVENTS.RISK_ANALYSIS_RISKS_UPDATED);

    onClose();
  };

  const { data } = useQuery(QUERY_KEYS.GET_SINGLE_RISK, () => assetRisksApi.getSingleAssetRisk(riskId as Id), {
    enabled: isEdit && !!riskId && open,
  });

  const initialValues = isEdit ? data : undefined;

  const formik = useFormik<CreateAssetRiskFormInput>({
    initialValues: {
      name: initialValues?.name || '',
      riskIdentificationWay: initialValues?.riskIdentificationWay || '',
      primaryConsequence: initialValues?.primaryConsequence || '',
      probability: initialValues?.probability || 1,
      consequence: initialValues?.consequence || 1,
      consequencesDescription: initialValues?.consequencesDescription || '',
      description: initialValues?.description || '',
      decision: initialValues?.decision || 'not_decided',
      affectIndividuals: initialValues?.affectIndividuals || false,
      place: initialValues?.place || 'internal',
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      name: yup.string().required(t(validation_messages.required)),
      riskIdentificationWay: yup.string().required(t(validation_messages.required)),
      primaryConsequence: yup.string().required(t(validation_messages.required)),
      probability: yup.string().required(t(validation_messages.required)),
      consequence: yup.string().required(t(validation_messages.required)),
      consequencesDescription: yup.string().required(t(validation_messages.required)),
      description: yup.string().required(t(validation_messages.required)),
    }),
    onSubmit,
  });

  return (
    // @ts-ignore
    <DialogGrid
      dialogActions={
        <ButtonsGrid>
          {/* @ts-ignore */}
          <ColoredButton customColor='none' disabled={mutation.isLoading} onClick={onClose} variant='outlined'>
            {t(general_messages.cancel)}
          </ColoredButton>
          {/* @ts-ignore */}
          <ColoredButton customColor='secondary' disabled={mutation.isLoading} form={FORM_ID} type='submit' variant='outlined'>
            {t(general_messages.save)}
          </ColoredButton>
        </ButtonsGrid>
      }
      onClose={onClose}
      open={open}
      title={isEdit ? t(asset_risk_messages.risk_dialog.edit_title) : t(asset_risk_messages.risk_dialog.add_title)}
    >
      <form id={FORM_ID} onSubmit={formik.handleSubmit}>
        {/* @ts-ignore */}
        <CenteredGrid gridGap={2} withoutPadding>
          <FormTextInput formik={formik} id='name' label={t(asset_risk_messages.risk_dialog.nameLabel)} />
          <FormTextInput formik={formik} id='description' label={t(asset_risk_messages.risk_dialog.descriptionLabel)} multiline rows={3} />
          <FormTextInput formik={formik} id='riskIdentificationWay' label={t(asset_risk_messages.risk_dialog.riskIdentificationWayLabel)} />
          <FormSelect
            formik={formik}
            id='probability'
            label={t(asset_risk_messages.risk_dialog.probabilityLabel)}
            options={RISK_ANALYSIS_PROBABILITY_SELECT}
          />
          <FormSelect
            formik={formik}
            id='consequence'
            label={t(asset_risk_messages.risk_dialog.consequenceLabel)}
            options={RISK_ANALYSIS_CONSEQUENCES_SELECT}
          />
          <FormTextInput formik={formik} id='primaryConsequence' label={t(asset_risk_messages.risk_dialog.primaryConsequenceLabel)} />
          <FormTextInput
            formik={formik}
            id='consequencesDescription'
            label={t(asset_risk_messages.risk_dialog.consequencesDescriptionLabel)}
          />
          <FormSelect
            formik={formik}
            id='decision'
            label={t(asset_risk_messages.risk_dialog.decisionLabel)}
            options={RISK_ANALYSIS_DECISION_SELECT}
          />
          <FormSelect
            formik={formik}
            id='place'
            label={t(asset_risk_messages.risk_dialog.placeLabel)}
            options={RISK_ANALYSIS_PLACE_SELECT}
          />
          <FormCheckbox formik={formik} id='affectIndividuals' label={t(asset_risk_messages.risk_dialog.affectIndividualsLabel)} />
        </CenteredGrid>
      </form>
    </DialogGrid>
  );
};

export default AssetRiskDialog;
