import React from 'react';

// import { useTranslation } from 'react-i18next';
//
// import ColoredButton from 'components/ColoredButton';
// import Section from 'components/Section';
// import TextButton from 'components/TextButton/TextButton';
// import asset_risk_messages from 'messages/asset_risk_messages';
// import { useHelpDialogContext } from 'reactContext/HelpDialogContext/HelpDialogContext';
//
// import useStyles from './RiskAnalysisTasksTable.styles';

const RiskAnalysisTasksTable: React.FC = () =>
  // const { t } = useTranslation();
  // const { showHelpDialog } = useHelpDialogContext();

  // const showRiskAnalysisTasksTableIntroductionDialog = () => {
  //   showHelpDialog({
  //     title: t(asset_risk_messages.risk_task_table_introduction.title),
  //     body: t(asset_risk_messages.risk_task_table_introduction.body),
  //   });
  // };

  // const onAdd = () => {
  //   console.log('Add button clicked');
  // };

  // const styles = useStyles();

  null;

// TODO use it in b5
// return (
//   <Section
//     paddingTop={3}
//     showSeparator
//     smallPadding
//     title={asset_risk_messages.risk_task_table.title}
//     titleLineAddon={[
//       <TextButton key='introduction' onClick={showRiskAnalysisTasksTableIntroductionDialog}>
//         {t(asset_risk_messages.risk_task_table_introduction.button)}
//       </TextButton>,
//       // @ts-ignore
//       <ColoredButton customColor='secondary' onClick={onAdd}>
//         {t(asset_risk_messages.risk_task_table.add_button)}
//       </ColoredButton>,
//     ]}
//     titleVariant='h3'
//   >
//     TABLE
//   </Section>
// );
export default RiskAnalysisTasksTable;
