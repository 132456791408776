import React, { useEffect, useMemo } from 'react';

import { CircularProgress, Typography } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import FormFilesUploader from 'components/FormFilesUploader/FormFilesUploader';
import FormSelect from 'components/FormSelect';
import FormTextInput from 'components/FormTextInput';
import TypographyWithHTML from 'components/TypographyWithHTML/TypographyWithHTML';
import UserAvatarWithDescription from 'components/UserAvatarWithDescription/UserAvatarWithDescription';
import activeUserAssetTasksApi from 'config/api/activeUserAssetTasks/activeUserAssetTasks';
import { ActiveUserAssetTasksFormInput } from 'config/api/activeUserAssetTasks/activeUserAssetTasks.types';
import { commonApi } from 'config/api/common';
import organizationAssetTasksApi from 'config/api/organizationAssetTasks/organizationAssetTasks';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import TASK_STATUSES_SELECT from 'config/dictionaries/TASK_STATUSES_SELECT';
import EVENTS from 'config/events/pubsub';
import safeT from 'helpers/safeT/safeT';
import useBoolState from 'hooks/useBoolState';
import assets_messages from 'messages/assets_messages';
import general_messages from 'messages/general_messages';
import PATHS from 'router/PATHS';
import { FileInForm } from 'types/FileForm';

import DateWithCalendarIcon from '../../DateWithCalendarIcon/DateWithCalendarIcon';
import ButtonsGrid from '../_components/ButtonsGrid';
import DialogGrid from '../_components/DialogGrid';

import useStyles from './AssetTaskDialog.styles';

type Props = {
  open: boolean;
  onClose: () => void;
  taskId: number | null;
  forActiveUser: boolean;
};

type FormType = ActiveUserAssetTasksFormInput;

const AssetTaskDialog: React.FC<Props> = ({ open, onClose, taskId, forActiveUser }) => {
  const FORM_ID = useMemo(() => `AssetTaskForm-${taskId}`, [taskId]);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const query = useMemo(() => {
    const fn = forActiveUser
      ? activeUserAssetTasksApi.getSingleActiveUserAssetTask
      : organizationAssetTasksApi.getSingleOrganizationAssetTasks;
    const keyBase = forActiveUser ? QUERY_KEYS.GET_USER_ASSET_TASK_BY_ID : QUERY_KEYS.GET_ORGANIZATION_ASSET_TASK_BY_ID;

    return {
      fn,
      keyBase,
    };
  }, [forActiveUser, taskId]);

  const { data, isLoading } = useQuery([query.keyBase, taskId], () => query.fn(taskId as number), {
    refetchOnWindowFocus: false,
    enabled: !!taskId,
  });

  // It's used only for active user tasks
  const taskMutation = useMutation(activeUserAssetTasksApi.updateSingleActiveUserAssetTask(taskId!));
  const uploadFileMutation = useMutation(activeUserAssetTasksApi.uploadSingleActiveUserAssetTaskDocument(taskId!));
  const removeFileMutation = useMutation(commonApi.removeAttachment);

  const { state: sending, setTrue: setIsSending, setFalse: setInNotSending } = useBoolState(false);
  const onSubmit = async (formData: FormType) => {
    if (!forActiveUser) return;
    setIsSending();
    try {
      await Promise.all([
        taskMutation.mutateAsync({
          comment: formData.comment,
          status: formData.status,
        }),
        ...(formData.filesToRemove?.map(fileId => removeFileMutation.mutateAsync(fileId)) || []),
      ]);
      // eslint-disable-next-line no-restricted-syntax
      for (const file of formData.files?.filter(({ isFromDb }) => !isFromDb) || []) {
        const fileToUpload = file as FileInForm;
        // eslint-disable-next-line no-await-in-loop
        await uploadFileMutation.mutateAsync({ file: fileToUpload.file });
      }
      enqueueSnackbar(t(general_messages.data_saved), { variant: 'success' });
      // formInstance.resetForm();
      PubSub.publish(EVENTS.ASSET_TASK_UPDATED);
      onClose();
    } catch (error) {
      enqueueSnackbar(t(general_messages.something_went_wrong), { variant: 'error' });
    } finally {
      setInNotSending();
    }
  };

  const form = useFormik<FormType>({
    onSubmit,
    initialValues: {
      comment: data?.comment || '',
      status: data?.status || 'started',
      files: null,
      filesToRemove: null,
    },
  });

  useEffect(() => {
    if (data) {
      form.setValues({
        comment: data.comment,
        status: data.status,
        files: data.documents.map(({ id, name, url }) => ({ id, name, url, isFromDb: true })),
        filesToRemove: null,
      });
    }
  }, [data]);

  const mutation = useMutation('test', () => Promise.resolve());

  const styles = useStyles();
  return (
    // @ts-ignore
    <DialogGrid
      dialogActions={
        forActiveUser ? (
          <ButtonsGrid>
            {/* @ts-ignore */}
            <ColoredButton customColor='none' disabled={mutation.isLoading} onClick={onClose} variant='outlined'>
              {t(general_messages.cancel)}
            </ColoredButton>
            {/* @ts-ignore */}
            <ColoredButton customColor='secondary' disabled={mutation.isLoading} form={FORM_ID} type='submit' variant='outlined'>
              {t(general_messages.save)}
            </ColoredButton>
          </ButtonsGrid>
        ) : (
          <CenteredGrid withoutPadding>
            {/* @ts-ignore */}
            <ColoredButton customColor='secondary' onClick={onClose} variant='outlined'>
              {t(general_messages.close)}
            </ColoredButton>
          </CenteredGrid>
        )
      }
      loading={isLoading}
      noPadding
      onClose={onClose}
      open={open}
      sending={sending}
      title={data?.name || t(general_messages.loading)}
    >
      {isLoading || !data ? (
        <CircularProgress />
      ) : (
        <form id={FORM_ID} onSubmit={form.handleSubmit}>
          <CenteredGrid gridGap={3}>
            <Link className={styles.linkCard} target='_blank' to={`${PATHS.YEAR_PLANNER_ASSETS_ACTIVITY}/${data.yearPlannerActivity.id}`}>
              <div>
                <Typography className={styles.linkLabel} variant='subtitle2'>
                  {t(assets_messages.asset_task_dialog.planner_activity_label)}
                </Typography>
                <Typography className={styles.linkName} variant='body1'>
                  {safeT(t, data.yearPlannerActivity.activity.nameTranslationKey, data.yearPlannerActivity.activity.name)}
                </Typography>
              </div>
              <ArrowForward color='secondary' />
            </Link>
            <div className={styles.baseDataSection}>
              <DateWithCalendarIcon date={data.dueDate} label={t(assets_messages.asset_task_dialog.due_date_label)} />
              <UserAvatarWithDescription
                description={['Owner:', data.asset.owner.profile.fullName]}
                userAvatarProps={{ size: 'm', userData: data.asset.owner.profile, userId: data.asset.owner.id }}
              />
            </div>
          </CenteredGrid>
          <div className={styles.divider} />
          <CenteredGrid className={styles.paddingTop} gridGap={3}>
            {!forActiveUser ? (
              <>
                <Typography variant='h4'>{t(assets_messages.asset_task_dialog.status_label)}</Typography>
                <Typography>{t(assets_messages.asset_task_status[data.status])}</Typography>

                <Typography variant='h4'>{t(assets_messages.asset_task_dialog.comment_label)}</Typography>
                <TypographyWithHTML>{data.comment}</TypographyWithHTML>
              </>
            ) : (
              <>
                <FormSelect
                  formik={form}
                  id='status'
                  label={t(assets_messages.asset_task_dialog.status_label)}
                  options={TASK_STATUSES_SELECT}
                />
                <FormTextInput formik={form} id='comment' label={t(assets_messages.asset_task_dialog.comment_label)} multiline rows={3} />
              </>
            )}
            <Typography variant='h4'>{t(assets_messages.asset_task_dialog.files_label)}</Typography>
            <Typography>{t(assets_messages.asset_task_dialog.files_description)}</Typography>
            {!isLoading && <FormFilesUploader formik={form} id='files' isPreview={!forActiveUser} removeId='filesToRemove' />}
          </CenteredGrid>
        </form>
      )}
    </DialogGrid>
  );
};

export default AssetTaskDialog;
