import React, { useMemo, useState } from 'react';

import { Divider } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import RiskAnalysisPrintTemplate from 'components/_print/_templates/RiskAnalysisPrintTemplate/RiskAnalysisPrintTemplate';
import { ContextMenuElement } from 'components/ContextMenu/ContextMenu';
import DetailsCardHeader from 'components/DetailsCardHeader/DetailsCardHeader';
import DialogViewWrapper from 'components/DialogViewWrapper';
import Loader from 'components/Loader';
import activeUserRiskAnalysesApi from 'config/api/activeUserRiskAnalyses/activeUserRiskAnalyses';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import EVENTS from 'config/events/pubsub';
import asset_risk_messages from 'messages/asset_risk_messages';
import UnauthorizedPage from 'pages/UnauthorizedPage';
import { useConfirmationModalContext } from 'reactContext/ConfirmationModalContext/ConfirmationModalContext';
import PATHS from 'router/PATHS';

import RiskAnalysisIntro from './_components/RiskAnalysisIntro/RiskAnalysisIntro';
import RiskAnalysisMatrix from './_components/RiskAnalysisMatrix/RiskAnalysisMatrix';
import RiskAnalysisMetadata from './_components/RiskAnalysisMetadata/RiskAnalysisMetadata';
import RiskAnalysisReportsTable from './_components/RiskAnalysisReportsTable/RiskAnalysisReportsTable';
import RiskAnalysisRisksTable from './_components/RiskAnalysisRisksTable/RiskAnalysisRisksTable';
import RiskAnalysisTasksTable from './_components/RiskAnalysisTasksTable/RiskAnalysisTasksTable';
import useStyles from './SingleRiskAnalysisPage.styles';

const SingleRiskAnalysisPage: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { riskAnalysisId } = useParams() as { riskAnalysisId: string };
  const [hasAccess, setHasAccess] = useState<boolean | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { showConfirmationModal } = useConfirmationModalContext();
  const [printTemplateReady, setPrintTemplateReady] = useState(false);

  const back = () => {
    history.push(PATHS.ROOT);
  };

  const { data: baseRiskData, isLoading: baseRiskDataLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_SINGLE_RISK_ANALYSIS, riskAnalysisId],
    queryFn: () => activeUserRiskAnalysesApi.getSingleActiveUserRiskAnalysis(riskAnalysisId),
    retry: 0,
    onError: () => {
      setHasAccess(false);
    },
    onSuccess: () => {
      setHasAccess(true);
    },
  });

  const removeMutation = useMutation(activeUserRiskAnalysesApi.removeRiskAnalysis);

  const contextMenu = useMemo(() => {
    if (!baseRiskData) return undefined;
    const elements: ContextMenuElement[] = [
      {
        label: t(asset_risk_messages.details_page_context_menu.delete),
        onClick: async () => {
          const confirmed = await showConfirmationModal({
            title: t(asset_risk_messages.delete_confirmation_title),
            body: t(asset_risk_messages.delete_confirmation_body),
          });
          if (confirmed) {
            await removeMutation.mutateAsync(riskAnalysisId);
            PubSub.publish(EVENTS.MY_ASSET_RISKS_UPDATED);
            enqueueSnackbar(t(asset_risk_messages.delete_success), { variant: 'success' });
            history.replace(PATHS.ROOT);
          }
        },
        disabled: !baseRiskData.canBeDeleted,
        //   TODO add delete_info
      },
    ];

    return elements;
  }, [baseRiskData]);

  const styles = useStyles();

  if (baseRiskDataLoading) return <Loader inner />;

  if (hasAccess === false) return <UnauthorizedPage />;

  return (
    <DialogViewWrapper contentSize='lg' maxWidth='lg' noCardPadding title={t(asset_risk_messages.details_page.title)}>
      <DetailsCardHeader
        contextMenu={contextMenu}
        onClose={back}
        printDisabled={!printTemplateReady}
        printTemplate={<RiskAnalysisPrintTemplate onReady={() => setPrintTemplateReady(true)} riskAnalysisId={riskAnalysisId} />}
        sticky
        title={t(asset_risk_messages.details_page.subtitle, { assetName: baseRiskData?.asset.name })}
      />
      <main className={styles.main}>
        <RiskAnalysisIntro />
        <Divider className={styles.divider} />
        <RiskAnalysisMetadata riskAnalysisId={riskAnalysisId} />
        <Divider className={styles.divider} />
        <RiskAnalysisMatrix riskAnalysisId={riskAnalysisId} />
        <Divider className={styles.divider} />
        <RiskAnalysisRisksTable riskAnalysisId={riskAnalysisId} />
        {baseRiskData?.asset && <RiskAnalysisReportsTable assetId={baseRiskData.asset.id} />}
        <RiskAnalysisTasksTable />
      </main>
    </DialogViewWrapper>
  );
};

export default SingleRiskAnalysisPage;
