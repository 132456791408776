const EVENTS = {
  ACTIVITIES_UPDATED: 'ACTIVITIES_UPDATED',
  MEETINGS_UPDATED: 'MEETINGS_UPDATED',
  AVAILABILITY_UPDATED: 'AVAILABILITY_UPDATED',
  INCIDENT_REPORTS_UPDATED: 'INCIDENT_REPORTS_UPDATED',
  ORGANIZATION_DATA_UPDATED: 'ORGANIZATION_DATA_UPDATED',
  API_MUTATION_ERROR: 'API_MUTATION_ERROR',
  API_FETCH_ERROR: 'API_FETCH_ERROR',
  USER_PROFILE_UPDATED: 'USER_PROFILE_UPDATED',
  CONTEXT_MENU_OPENED: 'CONTEXT_MENU_OPENED',
  ASSETS_UPDATED: 'ASSETS_UPDATED',
  ASSETS_YEAR_PLANNER_UPDATED: 'ASSETS_ACTIVITIES_UPDATED',
  WORKPLACES_UPDATED: 'WORKPLACES_UPDATED',
  WORKPLACES_TOTAL_COUNT_CHANGED: 'WORKPLACES_TOTAL_COUNT_CHANGED',
  EMERGENCY_REPORTED: 'EMERGENCY_REPORTED',
  WORKPLACE_DOCUMENTS_UPDATED: 'WORKPLACES_UPDATED',
  INCIDENT_GUIDE_RELATED_ASSETS_UPDATED: 'INCIDENT_GUIDE_RELATED_ASSETS_UPDATED',
  ASSET_TASK_UPDATED: 'ASSET_TASK_UPDATED',
  MY_ASSET_RISKS_UPDATED: 'MY_ASSET_RISKS_UPDATED',
  RISK_ANALYSIS_RISKS_UPDATED: 'RISK_ANALYSIS_RISKS_UPDATED',
  RISK_ANALYSIS_TASKS_UPDATED: 'RISK_ANALYSIS_TASKS_UPDATED',
  INCIDENTS_FOR_USER_UPDATED: 'INCIDENTS_FOR_USER_UPDATED',
};

export default EVENTS;
